<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <AppCategorys :catid="catid" />
    </template>
    <template #fallback>
      <AppLoading />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader";
// import AppContents from "@/components/Pages/AppContents";
import AppFooter from "@/components/layouts/AppFooter";
import AppLoading from "@/components/Spinners/AppLoading.vue";
import { defineAsyncComponent, Suspense } from "vue";
const AppCategorys = defineAsyncComponent(() =>
  import('@/components/Pages/AppCategorys')
)
export default {
  name: "Categorys",
  props: {
    catid: String,
  },
  components: {
    AppHeader,
    AppCategorys,
    AppFooter,
    AppLoading,
    Suspense,
  },
};
</script>

<style>
</style>